import './style.scss';
import waypoints from './node_modules/waypoints/lib/noframework.waypoints.min.js';

(function(Waypoint) {

  /*
  =========================
  SCROLL EVENTS
  =========================
  */
  var siteHeader = document.querySelector('header');
  var headerLinks = siteHeader.querySelectorAll('ul a');
  var productSections = document.querySelectorAll('[data-section]');
  var siteHeaderHeight;

  window.addEventListener('scroll', function() {
    if (window.scrollY > 1) {
      siteHeader.classList.add('header--is-fixed');
      siteHeaderHeight = siteHeader.offsetHeight - 1;
  // console.log(siteHeaderHeight);
    } else {
      siteHeader.classList.remove('header--is-fixed');
    }
  });


  productSections.forEach((section) => {
    var waypoint = new Waypoint({
      element: document.querySelector(`#${section.id}`),
      handler: function(direction) {
        if (direction === 'down') {
          removeActiveNav();
          var activeSection = this.element.dataset.section;
          if (document.querySelector(`a[href="#${activeSection}"]`)) {
            document.querySelector(`a[href="#${activeSection}"]`).classList.add('active');
          }
          if (activeSection === 'product' && direction === 'down') {
            document.body.classList.add('mobile-nav--is-fixed');
            document.querySelector('.site-nav ul').style.top = '54px';
          }
        } else {
          removeActiveNav();
          var activeSection = this.element.dataset.section;
          if (activeSection != 'product') {
            document.querySelector(`a[href="#${activeSection}"]`).parentElement.previousElementSibling.querySelector('a').classList.add('active');
          }
          if (activeSection === 'product' && direction === 'up') {
            document.body.classList.remove('mobile-nav--is-fixed');
          }
        }
      },
      offset: `25%`
    });
  });

  function removeActiveNav() {
    headerLinks.forEach((link) => {
      link.classList.remove('active');
    });
  }

  
  /*
  =========================
  CLICK EVENTS
  =========================
  */
  document.body.addEventListener('click', function(e) {
    
    if (e.target.classList.contains('launchModal')) {
      document.body.classList.add('modal--is-open');
      var modal = '.' + e.target.dataset.modal;
      document.querySelector(modal).classList.add('modal--is-active');
    }

    if (e.target.classList.contains('closeModal')) {
      var modal = e.target.parentElement.parentElement.parentElement;
      document.body.classList.remove('modal--is-open');
      modal.classList.remove('modal--is-active');
    }

    if (e.target.classList.contains('modal')) {
      var modal = e.target;
      document.body.classList.remove('modal--is-open');
      modal.classList.remove('modal--is-active');
    }

    if (e.target.classList.contains('header-cta')) {
      console.log('clicked');
      ga('send', 'event', {
        eventCategory: 'Button',
        eventAction: 'Lets Talk',
        eventLabel: 'Site Header'
      });
    }

  });


  /*
  =========================
  KEYBOARD EVENTS
  =========================
  */
  document.body.addEventListener('keyup', function(e) {
    if (e.keyCode === 27) {
      document.body.classList.remove('modal--is-open');
      var allModals = document.querySelectorAll('.modal');
      for (var i = 0; i < allModals.length; i++) {
        allModals[i].classList.remove('modal--is-active');
      }
    }
  });

})(Waypoint);
